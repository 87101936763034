function registerServiceWorker() {
  return navigator.serviceWorker
    .register("/sw.js")
    .then((registration) => registration)
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      return null;
    });
}

let serviceWorkerRegistration: ServiceWorkerRegistration | null;

export function getServiceWorker() {
  if (serviceWorkerRegistration && "showNotification" in serviceWorkerRegistration) {
    return serviceWorkerRegistration;
  }
  return null;
}

function WebNotification() {
  if (typeof navigator !== "undefined" && "serviceWorker" in navigator) {
    registerServiceWorker()
      .then((data) => {
        serviceWorkerRegistration = data;
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  }

  return null;
}

export default WebNotification;
