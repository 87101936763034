export const TENNIS_LIVE_DATA_TYPE_FRAGMENT = `
  fragment TennisLiveDataTypeFragment on TennisLiveDataType {
    gameId
    additionalData
    period
    bestOf
    surfaceType
    isPlayer1Serving
    player1Points
    player2Points
    player1GamesBySet
    player2GamesBySet
    player1AcesBySet
    player2AcesBySet
    player1BreaksBySet
    player2BreaksBySet
    player1DoubleFaultsBySet
    player2DoubleFaultsBySet
    player1TieBreaksBySet
    player2TieBreaksBySet
    player1PointsBySet
    player2PointsBySet
    # isAtTimeout
  }
`;

export enum TennisLiveBestOfEnum {
  UNKNOWN = "UNKNOWN",
  BEST_OF_ONE = "BEST_OF_ONE",
  BEST_OF_THREE = "BEST_OF_THREE",
  BEST_OF_FIVE = "BEST_OF_FIVE",
}

export type TennisLiveDataTypeFragment = {
  gameId: string;
  additionalData: string;
  period: string;
  bestOf: TennisLiveBestOfEnum,
  surfaceType: string,
  isPlayer1Serving: boolean;
  player1Points: string;
  player2Points: string;
  player1GamesBySet: number[] | null;
  player2GamesBySet: number[] | null;
  player1AcesBySet: number[] | null;
  player2AcesBySet: number[] | null;
  player1BreaksBySet: number[] | null;
  player2BreaksBySet: number[] | null;
  player1DoubleFaultsBySet: number[] | null
  player2DoubleFaultsBySet: number[] | null;
  player1TieBreaksBySet: number[] | null;
  player2TieBreaksBySet: number[] | null;
  player1PointsBySet: number[] | null;
  player2PointsBySet: number[] | null;
  // isAtTimeout: boolean;
};
