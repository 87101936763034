
// Function to detect if the current browser is Safari
export const detectSafari = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /safari/.test(userAgent) && !/chrome/.test(userAgent); // Detects Safari, excluding Chrome
};

// Function to update whether a scrollbar is present
export const updateScrollbarState = (containerRef: React.RefObject<HTMLDivElement>, setHasScrollbar: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (containerRef.current) {
        const { scrollHeight, clientHeight } = containerRef.current;
        setHasScrollbar(scrollHeight > clientHeight);
    }
};

// Function to detect scrollbar width
export const calculateScrollbarWidth = () => {
    const testDiv = document.createElement("div");
    testDiv.style.visibility = "hidden";
    testDiv.style.overflow = "scroll";
    testDiv.style.width = "100px";
    testDiv.style.height = "100px";
    document.body.appendChild(testDiv);

    const scrollbarWidthValue = testDiv.offsetWidth - testDiv.clientWidth;
    document.body.removeChild(testDiv);

    return scrollbarWidthValue;
};