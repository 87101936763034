"use client";

import useGetGameLiveData, { GetGameLiveDataVariables, useGetGameLiveDataQuery } from "@/hooks/useGetGameLiveData";
import React, { useMemo } from "react";
import { useAppSelector } from "@/store/store";
import { useFilterContextSelector } from "../Filters/FilterContext";
import { Sports } from "../Enums/SportsEnum";
import { League } from "../Enums/LeagueEnum";



type GameLiveDataInternalProps = {
  variables: GetGameLiveDataVariables;
};

function GameLiveDataInternal({ variables }: GameLiveDataInternalProps) {
  useGetGameLiveDataQuery(variables);
  useGetGameLiveData(variables);
  return null;
}

function GameLiveDataSubCall() {
  const queryState = useFilterContextSelector((ctx) => ctx.filterState);
  const leaguesList = useAppSelector((state) => state.constantsReducer.leagues);
  const sportsGroup = useMemo(() => {
    const sportsLeaguesMap: Partial<Record<Sports, League[]>> = {};
    leaguesList?.forEach((league) => {
      if (!(league.sportId in sportsLeaguesMap)) {
        sportsLeaguesMap[league.sportId] = [];
      }
      sportsLeaguesMap[league.sportId]!.push(league.id);
    });
    return sportsLeaguesMap;
  }, [leaguesList]);

  if (queryState.leagues === undefined) {
    return <>{Object.values(Sports)?.map((sport, i) => <GameLiveDataInternal variables={{ sport }} key={i} />)}</>;
  }

  const sports: Sports[] = [];
  let leagues: League[] = [];

  Object.keys(sportsGroup).forEach((sg) => {
    const sportsGroupData = sportsGroup[sg as Sports]!;
    const sportsLeagueSelected = sportsGroupData.filter((lg) => queryState.leagues?.includes(lg));
    if (sportsGroupData?.length === sportsLeagueSelected?.length) {
      sports.push(sg as Sports);
    } else {
      leagues = leagues.concat(sportsLeagueSelected);
    }
  });

  return (
    <>
      {sports?.map((sport, i) => <GameLiveDataInternal variables={{ sport }} key={i} />)}
      {leagues?.map((league, i) => <GameLiveDataInternal variables={{ league }} key={i} />)}
    </>
  );
}

export default GameLiveDataSubCall;
