import React, { useEffect, useState, useRef } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { webSocket } from "@/apollo/factories";
import { hoursToMilliseconds } from "date-fns";
import { showWebSocketDisconnectNotification } from "../WebNotification/helper";

const INACTIVITY_LIMIT = hoursToMilliseconds(4); // 4 hours in milliseconds

function ActivityChecker() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [listenersActive, setListenersActive] = useState<boolean>(true);
  const timeoutRef = useRef<number | null>(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      setIsModalOpen(true);
      setListenersActive(false); // Disable listeners when modal is open
      webSocket.wsClient?.dispose();
      showWebSocketDisconnectNotification();
    }, INACTIVITY_LIMIT);
  };

  useEffect(() => {
    const handleActivity = () => {
      resetTimeout();
      if (isModalOpen) {
        setIsModalOpen(false);
      }
    };

    if (listenersActive) {
      window.addEventListener("mousemove", handleActivity);
      window.addEventListener("mousedown", handleActivity);
      window.addEventListener("keydown", handleActivity);
      window.addEventListener("scroll", handleActivity);
      window.addEventListener("touchstart", handleActivity);
    }

    // Initialize timeout on mount
    resetTimeout();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("mousedown", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("scroll", handleActivity);
      window.removeEventListener("touchstart", handleActivity);
    };
  }, [isModalOpen, listenersActive]);

  const handleClose = () => {
    setIsModalOpen(false);
    resetTimeout(); // Reset the activity timer
    setListenersActive(true); // Re-enable listeners
    window.location.reload();
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Disconnected</DialogTitle>
      <DialogContent>
        You have been inactive for 4 hours. You are Disconnected.
        <br />
        Please refresh the page to connect again
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ActivityChecker;
