export const BASKETBALL_LIVE_DATA_TYPE_FRAGMENT = `
  fragment BasketballLiveDataTypeFragment on BasketballLiveDataType {
    gameId
    additionalData
    period
    gameTimeSeconds
    homeTeamHasPossession
    #isClockRunning
    awayTeamFoulsByQuarter
    homeTeamFoulsByQuarter
    awayTeamFreeThrowsMissedByQuarter
    homeTeamFreeThrowsMissedByQuarter
    awayTeamFreeThrowsPendingByQuarter
    homeTeamFreeThrowsPendingByQuarter
    awayTeamFreeThrowsScoredByQuarter
    homeTeamFreeThrowsScoredByQuarter
    awayTeamThreePointersByQuarter
    homeTeamThreePointersByQuarter
    awayTeamTotalPointsByQuarter
    homeTeamTotalPointsByQuarter
    awayTeamTwoPointersByQuarter
    homeTeamTwoPointersByQuarter
  }
`;

export type BasketballLiveDataTypeFragment = {
  gameId: string;
  additionalData: string;
  period: string;
  gameTimeSeconds: number;
  homeTeamHasPossession: boolean;
  // isClockRunning: boolean | null;
  awayTeamFoulsByQuarter: number[] | null;
  homeTeamFoulsByQuarter: number[] | null;
  awayTeamFreeThrowsMissedByQuarter: number[] | null;
  homeTeamFreeThrowsMissedByQuarter: number[] | null;
  awayTeamFreeThrowsPendingByQuarter: number[] | null;
  homeTeamFreeThrowsPendingByQuarter: number[] | null;
  awayTeamFreeThrowsScoredByQuarter: number[] | null;
  homeTeamFreeThrowsScoredByQuarter: number[] | null;
  awayTeamThreePointersByQuarter: number[] | null;
  homeTeamThreePointersByQuarter: number[] | null;
  awayTeamTotalPointsByQuarter: number[] | null;
  homeTeamTotalPointsByQuarter: number[] | null;
  awayTeamTwoPointersByQuarter: number[] | null;
  homeTeamTwoPointersByQuarter: number[] | null;
};
