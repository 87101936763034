export const BASEBALL_LIVE_DATA_TYPE_FRAGMENT = `
  fragment BaseballLiveDataTypeFragment on BaseballLiveDataType {
    gameId
    additionalData
    period
    isHomeTeamBatting
    currentStrikes
    currentBalls
    awayTeamScoreByInning
    homeTeamScoreByInning
    awayTeamHomeRunByInning
    homeTeamHomeRunByInning
    awayTeamOutsByInning
    homeTeamOutsByInning
    awayTeamGrandSlamsByInning
    homeTeamGrandSlamsByInning
    awayTeamCurrentBases
    homeTeamCurrentBases
  }
`;

export type BaseballLiveDataTypeFragment = {
  gameId: number;
  additionalData: string;
  period: string;
  isHomeTeamBatting: boolean;
  currentStrikes: number;
  currentBalls: number;
  awayTeamScoreByInning: number[] | null;
  homeTeamScoreByInning: number[] | null;
  awayTeamHomeRunByInning: number[] | null;
  homeTeamHomeRunByInning: number[] | null;
  awayTeamOutsByInning: number[] | null;
  homeTeamOutsByInning: number[] | null;
  awayTeamGrandSlamsByInning: number[] | null;
  homeTeamGrandSlamsByInning: number[] | null;
  awayTeamCurrentBases: boolean[] | null;
  homeTeamCurrentBases: boolean[] | null;
};
