import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { webSocket, lastMessageTime } from "@/apollo/factories";
import { differenceInMinutes } from "date-fns";
import { showWebSocketDisconnectNotification } from "../WebNotification/helper";

function WebSocketStatus() {
  const [wsStatus, setWsStatus] = useState<string>("connected");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document?.visibilityState === "visible" && differenceInMinutes(Date.now(), lastMessageTime.time) > 5) {
        webSocket.wsClient?.restart();
      }
    };

    document?.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document?.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let timeout: number | undefined;
    let activeSocket: WebSocket;

    const handleOpen = () => {
      setWsStatus("connected");
      if (timeout) {
        clearTimeout(timeout);
      }
      setIsModalOpen(false);
    };

    const handleDisconnect = () => {
      // eslint-disable-next-line no-console
      console.log("WebSocket disconnected or error occurred", wsStatus);
      timeout = window.setTimeout(() => {
        setWsStatus("disconnected");
        setIsModalOpen(true);
        showWebSocketDisconnectNotification(
          "Web Socket have been disconnected due to some issue. Refresh the page to connect again.",
        );
      }, 3000); // Wait for 3 seconds before showing the modal
    };

    const connect = () => {
      activeSocket = webSocket.wsClient as unknown as WebSocket; // Directly cast to WebSocket for event handling
      if (activeSocket) {
        // I don't think this works
        activeSocket.onopen = handleOpen;
        activeSocket.onclose = handleDisconnect;
        activeSocket.onerror = handleDisconnect;

        activeSocket.onmessage = (event) => {
          const message = JSON.parse(event.data);
          if (message.type === "connection_ack") {
            handleOpen();
          } else if (message.type === "error") {
            handleDisconnect();
          }
        };
      }
    };

    connect();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      if (activeSocket) {
        activeSocket.onopen = null;
        activeSocket.onclose = null;
        activeSocket.onerror = null;
        activeSocket.onmessage = null;
        activeSocket.close?.();
      }
    };
  }, [wsStatus]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">WebSocket Disconnected</DialogTitle>
      <DialogContent>WebSocket connection lost. Please check your network.</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WebSocketStatus;
