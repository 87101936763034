"use client";

import { locales } from "@/i18n";
import { usePathname } from "next/navigation";
import React from "react";

const baseUrl = process.env.NEXT_PUBLIC_SITE_URL || "https://picktheodds.app";

function AppHreflLangTags() {
  const pathname = usePathname(); // Get the current path
  // Extract the path after the locale
  const pathWithoutLocale = pathname.replace(new RegExp(`^\\/(${locales.join("|")})`), "");

  if (process.env.NODE_ENV !== "production") return null;
  return (
    <>
      {locales.map((loc) => (
        <link key={loc} rel="alternate" hrefLang={loc} href={`${baseUrl}/${loc}${pathWithoutLocale}`} />
      ))}
      {/* Default language fallback */}
      <link rel="alternate" hrefLang="x-default" href={`${baseUrl}/en${pathWithoutLocale}`} />
    </>
  );
}

export default AppHreflLangTags;
