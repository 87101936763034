// import { getServiceWorker } from "@/components/WebNotification";

import { getServiceWorker } from "./WebNotification";

const defaultNotificationOptions = {
  icon: "/images/darkicon.png",
};

export function requestNotificationPermission() {
  if (typeof navigator === "undefined" || !("serviceWorker" in navigator) || !("Notification" in window)) {
    return undefined;
  }
  return Notification.requestPermission();
}

function showNotification(options?: NotificationOptions) {
  if (typeof navigator !== "undefined" && "serviceWorker" in navigator && "Notification" in window) {
    if (Notification.permission === "denied") return;
    if (Notification.permission === "granted") {
      getServiceWorker()?.showNotification?.("Pick The Odds!", options);
    } else {
      requestNotificationPermission()?.then((result) => {
        if (result === "granted") {
          getServiceWorker()?.showNotification?.("Pick The Odds!", options);
        }
        // eslint-disable-next-line no-console
      }).catch((err) => console.error(err));
    }
  }
}

export function showNewDataNotification(msg?: string) {
  const options = {
    ...defaultNotificationOptions,
    body: msg || "An new item Alert has been found with your filters!",
  };
  showNotification(options);
  return null;
}

export function showGreaterRoiNotification(roi: number, msg?: string) {
  const options = {
    ...defaultNotificationOptions,
    body: msg || `An new item with ROI greater than ${roi}% has been found!`,
  };
  showNotification(options);
  return null;
}

export function showWebSocketDisconnectNotification(msg?: string) {
  const options = {
    ...defaultNotificationOptions,
    body: msg || `You have been inactive for 4 hours. You are Disconnected. Please refresh the page to connect again.`,
  };
  showNotification(options);
  return null;
}
