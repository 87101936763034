export const SOCCER_LIVE_DATA_TYPE_FRAGMENT = `
  fragment SoccerLiveDataTypeFragment on SoccerLiveDataType {
    gameId
    additionalData
    period
    gameTimeSeconds
    homeTeamHasPossession
    awayTeamScoreByHalf
    homeTeamScoreByHalf
    awayTeamCornersByHalf
    homeTeamCornersByHalf
    awayTeamFoulsByHalf
    homeTeamFoulsByHalf
    awayTeamFreeKicksByHalf
    homeTeamFreeKicksByHalf
    awayTeamGoalKicksByHalf
    homeTeamGoalKicksByHalf
    awayTeamOffsidesByHalf
    homeTeamOffsidesByHalf
    awayTeamPenaltiesByHalf
    homeTeamPenaltiesByHalf
    awayTeamRedCardsByHalf
    homeTeamRedCardsByHalf
    awayTeamSubsByHalf
    homeTeamSubsByHalf
    awayTeamThrowInsByHalf
    homeTeamThrowInsByHalf
    awayTeamYellowCardsByHalf
    homeTeamYellowCardsByHalf
    # isClockRunning
  }
`;

export type SoccerLiveDataTypeFragment = {
  gameId: string;
  additionalData: string;
  period: string;
  gameTimeSeconds: number;
  homeTeamHasPossession: boolean;
  awayTeamScoreByHalf: number[] | null;
  homeTeamScoreByHalf: number[] | null;
  awayTeamCornersByHalf: number[] | null;
  homeTeamCornersByHalf: number[] | null;
  awayTeamFoulsByHalf: number[] | null;
  homeTeamFoulsByHalf: number[] | null;
  awayTeamFreeKicksByHalf: number[] | null;
  homeTeamFreeKicksByHalf: number[] | null;
  awayTeamGoalKicksByHalf: number[] | null;
  homeTeamGoalKicksByHalf: number[] | null;
  awayTeamOffsidesByHalf: number[] | null;
  homeTeamOffsidesByHalf: number[] | null;
  awayTeamPenaltiesByHalf: number[] | null;
  homeTeamPenaltiesByHalf: number[] | null;
  awayTeamRedCardsByHalf: number[] | null;
  homeTeamRedCardsByHalf: number[] | null;
  awayTeamSubsByHalf: number[] | null;
  homeTeamSubsByHalf: number[] | null;
  awayTeamThrowInsByHalf: number[] | null;
  homeTeamThrowInsByHalf: number[] | null;
  awayTeamYellowCardsByHalf: number[] | null;
  homeTeamYellowCardsByHalf: number[] | null;
  // isClockRunning: boolean,
};
